import { Match } from 'navigo';
import { initModule } from '../services/initModule.js';

export default {
    '/task/:taskId': {
        as: 'task',
        uses: (match: Match) => {
            import(
                /* webpackChunkName: "user-task" */ '../../TaskModule/TaskController'
            ).then((module) => {
                initModule(module.default, match);
            });
        },
    },
};
