import React, { StrictMode } from 'react';
import { createPortal } from 'react-dom';
import { ThemeProvider } from '@mui/material';
import { Root, createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import tasksTabsSlice, { activeTab, resetTabs } from 'fsrc/Task/store/tasksTabsSlice';

import theme from 'Lib/Mui/theme';
import container from 'Services/container';
import StyledSnackbarProvider from 'Lib/Notistack/StyledSnackbarProvider';
import Action from 'Lib/Notistack/DefaultAction';
import { TTabs } from '../types/TasksTabsState';
import LinksTabCount from '../components/LinksTable/LinksTabCount';
import HistoryTabCount from '../components/HistoryTable/HistoryTabCount';
import TimesheetTabCount from '../components/TimesheetTable/TimesheetTabCount';
import LinksTableView from '../components/LinksTable/LinksTableView';
import HistoryTableView from '../components/HistoryTable/HistoryTableView';
import TimesheetTableView from '../components/TimesheetTable/TimesheetTableView';

type TData = {
    taskId: number;
}

let rootContainer: Root;

function initForTaskFormTabs(data: TData) {
    const { taskId } = data;
    const root = document.getElementById(`task__tasks_form-tab-container${taskId}`) as HTMLDivElement;
    rootContainer = createRoot(root);

    const { store, injectReducer } = container;

    injectReducer('tasksTabsSliceReducer', tasksTabsSlice);

    rootContainer.render(
        <StrictMode>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <StyledSnackbarProvider
                        action={snackbarId => Action(snackbarId)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        hideIconVariant
                    >
                        {createPortal(<LinksTabCount taskId={taskId} />, document.getElementById(`task__linksTable-tab${taskId}_count`)!)}
                        {createPortal(<HistoryTabCount taskId={taskId} />, document.getElementById(`task__history-tab-${taskId}_count`)!)}
                        {createPortal(<TimesheetTabCount taskId={taskId} />, document.getElementById(`task__timesheet-tab-${taskId}_count`)!)}

                        {createPortal(<LinksTableView taskId={taskId} />, document.getElementById(`task__linksTable-tab${taskId}`)!)}
                        {createPortal(<HistoryTableView taskId={taskId} />, document.getElementById(`task__history-tab-container${taskId}`)!)}
                        {createPortal(<TimesheetTableView taskId={taskId} />, document.getElementById(`task__timesheet-tab-container${taskId}`)!)}

                    </StyledSnackbarProvider>
                </ThemeProvider>
            </Provider>
        </StrictMode>,
    );
}

type TActiveTabData = {
    tabId: TTabs;
}

function activateForTaskFormTab({ tabId }: TActiveTabData) {
    const { store } = container;
    store.dispatch(activeTab(tabId));
}

function closeTaskForm() {
    rootContainer?.unmount();

    const { store } = container;
    store.dispatch(resetTabs());
}

export default () => {
    const { events } = container;

    events.on(
        'task__formTaskTabs:initTaskFormTabs',
        initForTaskFormTabs,
        false,
    );

    events.on(
        'task__formTaskTabs:activateForTaskFormTab',
        activateForTaskFormTab,
        false,
    );

    events.on(
        'task__formTaskTabs:closeTaskForm',
        closeTaskForm,
        false,
    );
    events.on(
        'router:before-route',
        closeTaskForm,
        false,
    );
};
