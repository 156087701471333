import { ThemeProvider } from '@mui/material';
import React, { StrictMode } from 'react';
import { Root, createRoot } from 'react-dom/client';

import theme from 'Lib/Mui/theme';
import container from 'Services/container';
import StyledSnackbarProvider from 'Lib/Notistack/StyledSnackbarProvider';
import Action from 'Lib/Notistack/DefaultAction';
import ServiceFunctionsView from '../components/serviceFunctionsForm/ServiceFunctionsView';

type TData = {
    taskId: number;
}

let rootContainer: Root;

function initFormForOldTaskForm(data: TData) {
    const { taskId } = data;
    const root = document.getElementById(`itServicesModule_${taskId}`) as HTMLDivElement;
    rootContainer = createRoot(root);

    rootContainer.render(
        <StrictMode>
            <ThemeProvider theme={theme}>
                <StyledSnackbarProvider
                    action={snackbarId => Action(snackbarId)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    hideIconVariant
                >
                    <ServiceFunctionsView id={taskId} />
                </StyledSnackbarProvider>
            </ThemeProvider>
        </StrictMode>,
    );
}

function closeOldTaskForm() {
    rootContainer?.unmount();
}

export default () => {
    const { events } = container;
    events.on(
        'itServices__serviceFunctionForm:initForOldTaskForm',
        initFormForOldTaskForm,
        false,
    );
    events.on(
        'itServices__serviceFunctionForm:closeOldTaskForm',
        closeOldTaskForm,
        false,
    );
    events.on(
        'router:before-route',
        closeOldTaskForm,
        false,
    );
};
