import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import getCurrentFirmByTaskIdService from 'fsrc/Sales/services/getCurrentFirmByTaskIdService';
import FirmSelect from './FirmSelect';
import ContragentSelect from './ContragentSelect';
import ContactSelect from './ContactSelect';
import ContractSelect from './ContractSelect';
import ContactsOtherSelect from './ContactsOtherSelect';

type TProps = {
    taskId: number,
}

function OldSalesFormComponent({ taskId }: TProps) {
    const [firmId, setFirmId] = useState<number>(0);
    const [contragentId, setContragentId] = useState<number>(0);
    const [contactId, setContactId] = useState<number>(0);
    const [contractId, setConractId] = useState<number>(0);
    const [contactsOtherIds, setContactsOtherIds] = useState<number[]>([]);

    const [contrForContract, setContrForContract] = useState<number | null>(null);
    const [firmForContract, setFirmForContract] = useState<number | null>(null);
    const [projectForContract, setProjectForContract] = useState<number | null>(null);
    const [assigneeIdForContract, setAssigneeIdForContract] = useState<number | null>(null);
    const [dateForContract, setDateForContract] = useState<string | null>(null);

    const onContragentChangeHandle = useCallback((contrForContactId: number | null) => {
        setContrForContract(contrForContactId);
    }, []);

    const onFirmChangeHandle = useCallback((firmForContactId: number | null) => {
        setFirmForContract(firmForContactId);
    }, []);

    const handleChangeContact = useCallback((newContactId: number) => {
        setContactId(newContactId);
    }, []);

    const handleContractNewAdded = useCallback(
        (newContractId: number) => setConractId(+newContractId),
        [],
    );

    useEffect(() => {
        getCurrentFirmByTaskIdService(taskId).then((response) => {
            if (response?.firm?.id) {
                setFirmId(+response.firm.id);
                setFirmForContract(+response.firm.id);
            }

            if (response?.contragent?.id) {
                setContragentId(+response.contragent.id);
                setContrForContract(+response.contragent.id);
            }

            if (response?.cid) {
                setContactId(+response.cid);
            }

            if (response?.id_outbox) {
                setConractId(+response.id_outbox);
            }

            if (response?.contacts_more && response.contacts_more?.length) {
                const ids = response.contacts_more.map(contact => +contact.id);
                setContactsOtherIds(ids);
            }

            if (response?.id_project) {
                setProjectForContract(+response.id_project);
            }

            if (response?.aid) {
                setAssigneeIdForContract(+response.aid);
            }

            if (response?.date) {
                setDateForContract(response.date);
            }
        });
    }, [taskId]);

    return (
        <Grid container columnSpacing={2} style={{ padding: '15px' }}>
            <Grid item xs={2}>
                <ContactSelect
                    taskId={taskId}
                    contactId={contactId}
                    setNewContact={handleChangeContact}
                />
            </Grid>
            <Grid item xs={3}>
                <FirmSelect
                    taskId={taskId}
                    firmId={firmId}
                    onFirmChangeHandle={onFirmChangeHandle}
                />
            </Grid>
            <Grid item xs={3}>
                <ContragentSelect
                    taskId={taskId}
                    contragentId={contragentId}
                    onContragentChangeHandle={onContragentChangeHandle}
                />
            </Grid>
            <Grid item xs={4}>
                <ContractSelect
                    taskId={taskId}
                    contractId={contractId}
                    firmId={firmForContract}
                    contragentId={contrForContract}
                    projectId={projectForContract}
                    assigneeId={assigneeIdForContract}
                    date={dateForContract}
                    handleContractAdded={handleContractNewAdded}
                />
            </Grid>

            <Grid item xs={12}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        Контакты
                        {` (${contactsOtherIds.length})`}
                    </AccordionSummary>
                    <AccordionDetails>
                        <ContactsOtherSelect
                            taskId={taskId}
                            contactsIds={contactsOtherIds}
                        />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
}

export default OldSalesFormComponent;
